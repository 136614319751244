<template>
  <div class="products">
    <transition-group
      appear
      name="products__slide"
      tag="div"
      class="products__list"
    >
      <ProductListItem
        v-for="(product, i) in products"
        :key="product.id"
        :style="{ '--index': i }"
        :product="product"
        :is-cart-enabled="isCartEnabled"
        :show-stock-errors="showStockErrors"
        @click:add-to-cart="setQuantity(product, 1)"
        @input:add-to-cart="setQuantity(product, $event)"
      />
    </transition-group>
  </div>
</template>
<script>
import { ProductListItem } from "@lib/components";
import { notifications } from "@lib/modules/cart/helpers";
export default {
  components: {
    ProductListItem,
  },
  props: {
    products: { type: Array, required: true },
    isCartEnabled: {
      type: Boolean,
      default: true,
    },
    showStockErrors: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    updateFilter() {},
    clearAllFilters() {
      const filters = Object.keys(this.filters);
      filters.forEach((name) => {
        const prop = this.filters[name];
        prop.forEach((value) => {
          value.selected = false;
        });
      });
    },
    setProductQuantity(event, product) {
      product.qty = product;
    },
  },
};
</script>
<style lang="scss" scoped>
@use "~@lib/styles" as *;

.products {
  &__list {
    padding: 1rem 0;
  }
}

#category {
  box-sizing: border-box;
  @include for-desktop {
    max-width: 1240px;
    margin: 0 auto;
  }
}
.main {
  &.section {
    padding: var(--spacer-xs);
    @include for-desktop {
      padding: 0;
    }
  }
}
</style>
