<template>
  <div class="bottom-cart" @click.stop="$store.dispatch('ui/toggleMicrocart')">
    <div class="row m0">
      <div class="col-xs-2 p0">
        <div class="flex-center">
          <microcart-icon class="icon pointer" />
        </div>
      </div>
      <div class="col-xs-4 p0">
        <div class="flex-center">{{ productsCount }} Items</div>
      </div>
      <div class="col-xs-4 p0">
        <div class="flex-center">
          <SfPrice :regular="total | price" class="sf-price--big" />
        </div>
      </div>
      <div class="col-xs-2 p0">
        <div class="flex-center">
          <SfIcon
            icon="arrow_right"
            size="1.1rem"
            role="button"
            aria-label="back"
            @click="$emit('click:back', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import MicrocartIcon from "@theme/components/Header/MicrocartIcon";

import { SfPrice, SfIcon } from "@lib/components";

export default {
  components: {
    MicrocartIcon,
    SfPrice,
    SfIcon,
  },
  props: {
    productsCount: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
  },
};
</script>
<style lang="scss">
@use "~@lib/styles/helpers/breakpoints" as *;

.bottom-cart {
  position: relative;
  z-index: 1;
  --bottom-navigation-height: 3rem;
  --icon-color: var(--c-black);
  @include for-desktop() {
    display: none;
  }

  .row {
    width: 100%;
  }

  .header__icon {
    --icon-color: var(--c-black);
  }
}

.bottom-cart {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: var(--bottom-navigation-z-index, 1);
  display: flex;
  width: 100%;
  height: var(--bottom-navigation-height, 3.75rem);
  background: var(--bottom-navigation-background, var(--c-white));
  -webkit-box-shadow: var(
    --bottom-navigation-box-shadow,
    0 -2px 10px rgba(var(--c-dark-base), 0.15)
  );
  //   padding: var(--bottom-navigation-padding, 0 var(--spacer-sm));
  box-shadow: var(
    --bottom-navigation-box-shadow,
    0 -2px 10px rgba(var(--c-dark-base), 0.15)
  );

  .flex-center {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
  }
}
</style>
