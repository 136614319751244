<template>
  <div class="products-list-view">
    <DynamicScroller :items="products" :min-item-size="54" class="scroller">
      <template v-slot="{ item, index, active }">
        <DynamicScrollerItem
          :item="item"
          :active="active"
          :size-dependencies="[item.title]"
          :data-index="index"
        >
          <ProductListItem
            :style="{ '--index': index }"
            :product="item"
            :is-cart-enabled="isCartEnabled"
            :show-stock-errors="showStockErrors"
            :link="'/p/' + item.variantId"
            class="products__list-item"
          />
        </DynamicScrollerItem>
      </template>
    </DynamicScroller>
  </div>
</template>

<script>
import { ProductListItem } from "@lib/components";
import { DynamicScroller, DynamicScrollerItem } from "vue-virtual-scroller";
import "vue-virtual-scroller/dist/vue-virtual-scroller.css";

export default {
  components: {
    ProductListItem,
    DynamicScroller,
    DynamicScrollerItem,
  },
  props: {
    products: {
      required: true,
      type: Array,
    },
    isCartEnabled: {
      required: true,
      type: Boolean,
    },
    showStockErrors: {
      required: true,
      type: Boolean,
    },
  },
};
</script>
